/* eslint-disable import/prefer-default-export */
import React from 'react'
import { render } from 'react-dom'

type ComponentRequireContext = Record<string, () => Promise<{ default: React.FC }>>

const mountables: ComponentRequireContext = {}
/**
 * Function that registers a component to be available for rendering via the `react_component`
 * helper method in rails views.
 * @param name The name that the component should be available under when using `react_component`.
 * @param importFunction The `import()` call necessary to import the component.
 * The component should be available as the default export.
 */
export function registerComponent(name: string, importFunction: ComponentRequireContext[string]) {
  mountables[name] = importFunction
}

const mountComponents = () => {
  document.querySelectorAll<HTMLElement>('[data-react-component]').forEach((node) => {
    const importFunction = mountables[node.dataset.reactComponent as string]

    if (importFunction) {
      importFunction().then(({ default: Component }) => {
        let props: Record<string, any> = {}

        if (node.dataset.reactProps) {
          props = JSON.parse(node.dataset.reactProps)
        }

        render(

          <Component {...props} />,
          node,
        )
      })
    } else {
      console.error(`
Unable to render component '${node.dataset.reactComponent}'.

In order to render a react component with the \`react_component\` helper,
it must be registered using the \`registerComponent\` function
in the appropriate javascript entrypoint.
`)
    }
  })
}

document.addEventListener('DOMContentLoaded', mountComponents)
document.addEventListener('turbolinks:load', mountComponents)
